export const VALID_NAME_INPUTS = [
  8,
  9,
  16,
  17,
  20,
  32,
  35,
  36,
  37,
  38,
  39,
  40,
  45,
  46,
  219,
  ...Array.from({ length: 26 }).map((_, i) => i + 65), // from 65 to 90
];

export const addInputValidation = (selector, validInputs) => {
  const el = document.querySelector(selector);
  if (!el) {
    return;
  }

  el.addEventListener('keydown', event => {
    const code = !event.charCode ? event.which : event.charCode;

    if (validInputs.indexOf(code) === -1) {
      event.stopPropagation();
      event.preventDefault();

      return false;
    }
  });
};

export const addNameInputValidation = selector =>
  addInputValidation(selector, VALID_NAME_INPUTS);
