$.fn.scrollTo = function () {
  const self = $(this);
  const speed = self.data('speed') || 500; // scroll speed (in ms)
  const offsetAdj = self.data('offset') || 0; // amount to adjust the offset value (in px)
  $('html, body').animate(
    {
      scrollTop: $(self.attr('href')).offset().top - offsetAdj,
    },
    speed
  );
  return false;
};

document.addEventListener('DOMContentLoaded', () => {
  $('body').on('click', 'a[href*="#"]', e => {
    const $t = $(e.target).closest('a');
    if ($t.is('[href="#"],[href="#0"]')) {
      return;
    }
    if (
      window.location.pathname.replace(/^\//, '') ===
        $t[0].pathname.replace(/^\//, '') &&
      window.location.hostname === $t[0].hostname
    ) {
      // get element to scroll to
      let target = $t[0].hash;
      target = target.length ? target : $(`[name=${target.slice(1)}]`);
      // does the scroll target exist?
      if (target.length) {
        // scroll to target
        e.preventDefault();
        $t.scrollTo();
      }
    }
  });

  if ($('.reference-page').length > 0) {
    const $root = $('html, body');

    $("a[href*='#']").each((i, e) => {
      $(e).on('click', function () {
        $root.animate(
          {
            scrollTop: $($.attr(this, 'href')).offset().top - 140,
          },
          'slow'
        );

        return false;
      });
    });
  }
});
