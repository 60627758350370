export const addCarousel = () => {
  $('#js-content-carousel').on('click', '.carousel-control', function (e) {
    const $t = $(this);
    const scrollItem = $t.data('scroll');
    const scrollStart = parseInt($(scrollItem).css('left')); // starting position of the scroll items
    const itemWidth = parseInt($(scrollItem).css('width')); // width of the scroll item
    const totalItems = $(scrollItem).length - 1; // total number of items
    const $scrollFrom = $(`${scrollItem}.active`); // currently active item
    let $scrollTo; // set the item to scroll into view
    let scrollPos; // calculated scroll amount needed to show the prev/next item

    // set scroll operation based on button direction
    if ($t.data('direction') === 'next') {
      // scroll right
      scrollPos = scrollStart - itemWidth;
      $scrollTo = $scrollFrom.next();
      // disable "next" button if we've reached the last item
      $t.prop('disabled', $scrollTo.index() >= totalItems);
    } else {
      // scroll left
      scrollPos = scrollStart + itemWidth;
      $scrollTo = $scrollFrom.prev();
      // disable "previous" button if we've reached the first item
      $t.prop('disabled', $scrollTo.index() === 0);
    }

    // scroll/shift items one position to the left/right
    $(scrollItem).css('left', `${scrollPos}px`);
    // unset the previously active item
    $scrollFrom.removeClass('active');
    // set the new active item
    $scrollTo.addClass('active');
    // enable the opposite scroll button since we can no longer be at its boundary
    $t.siblings().prop('disabled', false);
    // adjust container height to fit content
    $('#js-content-carousel').css(
      'height',
      `${parseInt($scrollTo.css('height'))}px`
    );
    // update indicator position
    $('#js-carousel-indicators > li')
      .removeClass('active')
      .eq($scrollTo.index())
      .addClass('active');
  });
};

export default addCarousel;
