/*
 * Author: Brent Heimer
 * Date: 2018-11-09
 * Summary: Uses intersection observer to fire an animation for an svg path and number value
 * Lib Dependencies: CountUp.js
 *
 */

import { CountUp } from 'countup.js';

// TODO: decouple observer and antimated stat logic
(function () {
  const options = {
    autoLoad: {
      entries: [
        {
          selector: '.stat',
          fn: InitAnimStat,
          onCompleteOnly: true,
          config: { rootMargin: '0% 0%', threshold: 0.3 },
        },
      ],
    },
    defaultConfig: {
      root: null,
      rootMargin: '0% 0%',
      threshold: 0,
    },
  };

  function BrowserSupportCheck() {
    const canIntersect = window.IntersectionObserver !== undefined;
    if (!canIntersect) {
      LoadScriptCallback(
        'https://polyfill.io/v2/polyfill.min.js?features=IntersectionObserver',
        Init
      );
    }
    return canIntersect;
  }

  function LoadScriptCallback(scriptPath, callback) {
    const tag = document.createElement('script');
    tag.type = 'text/javascript';
    tag.async = true;
    tag.src = scriptPath;
    tag.onload = callback;
    document.getElementsByTagName('head')[0].appendChild(tag);
  }

  function Init() {
    if (BrowserSupportCheck()) {
      AutoLoad();
    }
  }

  function AutoLoad() {
    options.autoLoad.entries.forEach(entry => {
      LoadIntoObserver(entry);
    });
  }

  function LoadIntoObserver(entry) {
    if (entry.onCompleteOnly && document.readyState !== 'complete') {
      document.addEventListener('readystatechange', () => {
        if (document.readyState === 'complete') {
          LoadIntoObserver(entry);
        }
      });
    } else {
      const items = document.querySelectorAll(entry.selector);
      Observe(items, entry.fn, entry.config || options.defaultConfig);
    }
  }

  function Observe(collection, fn, config) {
    const observer = new IntersectionObserver((entries, self) => {
      entries.forEach(entry => {
        const isIntersecting =
          entry.isIntersecting === undefined
            ? entry.intersectionRatio > 0
            : entry.isIntersecting;
        if (isIntersecting) {
          // call function
          fn(entry.target);
          // detach observer
          self.unobserve(entry.target);
        }
      });
    }, config);

    for (let i = 0; i < collection.length; i++) {
      observer.observe(collection[i]);
    }
  }

  function InitAnimStat(elStat, countUpOpts) {
    const statInfoOptions = {
      value: 100,
      dir: null, // -1: ccw (defaults to css value)
      speed: null, // in seconds (defaults to css value)
    };
    const countUpOptions = {
      useEasing: true,
      useGrouping: true,
      separator: ',',
      decimal: '.',
    };

    Object.assign(countUpOptions, countUpOpts);

    let statInfo = null;
    try {
      statInfo = JSON.parse(elStat.dataset.statinfo);
      statInfo = Object.assign(statInfoOptions, statInfo);
    } catch (error) {
      console.error('failed parsing statinfo value', error);
      return;
    }

    const statValue = statInfo.value;
    const elStatTextNum = elStat.querySelector('.stat-value > span');
    elStatTextNum.textContent = statValue;

    // Set animated stat
    const elDiscFg = elStat.querySelector('.stat-discFg');
    elDiscFg.setAttribute('stroke-dasharray', `${statValue} 100`);
    if (statInfo.dir !== null) {
      elDiscFg.style.transform = `scaleX(${statInfo.dir})`;
    }
    elStat.classList.add('animate');
    if (statInfo.speed === null) {
      statInfo.speed = parseFloat(getComputedStyle(elDiscFg).animationDuration);
    }
    elDiscFg.style.animationDuration = `${statInfo.speed}s`;

    // Set animated text
    const aText = new CountUp(elStatTextNum, statValue, {
      ...countUpOptions,
      duration: statInfo.speed,
    });
    if (!aText.error) {
      aText.start();
    } else {
      console.error(aText.error);
    }
  }

  Init();
})();
