$.fn.stickySidebar = function () {
  const self = $(this);
  const smallHero = $('.hero').outerHeight(true);

  const sidebar = $('.sticky-sidebar');
  const article = $('.article');
  const bottom = $('#main')
    .nextUntil('footer')
    .add('footer')
    .add('.umb-tztgrid:nth-child(1)'); // '.umb-tztgrid added for issue on "/life-insurance-by-type"
  let bottomOffset = 0;
  let topOffset = 0;

  bottom.each(function () {
    bottomOffset += $(this).outerHeight(true);
  });

  topOffset = smallHero;

  if ($('.breadcrumb').length) {
    topOffset += $('.breadcrumb').outerHeight(true);
  }

  if (
    $(window).width() > 992 &&
    article.outerHeight(true) > sidebar.outerHeight(true)
  ) {
    $(self).attr({
      'data-offset-top': topOffset,
      'data-offset-bottom': bottomOffset + 80,
    });
  } else {
    $(self).removeAttr('data-spy');
  }
};
