const windowRef = $(window);
const headerRef = $('.js-header-bounce');

if (headerRef) {
  windowRef.on('scroll', function () {
    this.scrollY > 280
      ? headerRef.addClass('scrolling')
      : this.scrollY > 140
      ? headerRef.removeClass('scrolling').addClass('init-scrolling')
      : headerRef.removeClass('init-scrolling');
  });
}
