import 'jquery-validation-unobtrusive';
import Inputmask from 'inputmask';
import { format } from 'date-fns';
import * as siteConstants from './site-constants';
import { capitalCaseString } from '../utilities/string';
import { addNameInputValidation } from '../utilities/input-validation';

const IsGoodZip = function (zipcode) {
  if (zipcode.length < 5) {
    return false;
  }

  let isValid;
  $.ajax({
    url: '/validation/validatezipcode',
    data: { zip: zipcode },
    method: 'POST',
    timeout: 10000,
    async: false,
    success(data) {
      try {
        data = JSON.parse(data);
      } catch (e) {
        console.log('Unable to validate zip');
      }

      isValid = !data.error;
    },
    error(data) {
      console.error(data);
    },
  });

  return isValid;
};

const IsValidDate = function (value) {
  const today = new Date();
  const birthDate = new Date(value);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return (
    (age >= siteConstants.AGE_MIN && age < siteConstants.AGE_MAX) ||
    (age == siteConstants.AGE_MAX &&
      m === 0 &&
      today.getDate() == birthDate.getDate())
  );
};

/**
 * Applies validation rules to elements with given class names.
 * This function is seperated by design to allow post-instantiation application of validation rules. Depends on ValidationSupplemental() also being called to handle events.
 */
export const applyValidatorRules = function () {
  $('.mask-zip').each(function () {
    $(this).rules('add', 'validZip');
  });
  $('.mask-date').each(function () {
    $(this).rules('add', 'validDob');
  });
};

// Unobtrusive Validation Adapters
export const addValidatorMethods = function () {
  $.validator.addMethod(
    'validZip',
    IsGoodZip,
    'Please enter a valid zip code.'
  );

  $.validator.addMethod(
    'validDob',
    IsValidDate,
    `You must be between ${siteConstants.AGE_MIN} and ${siteConstants.AGE_MAX} years old to qualify.`
  );
};

export const modifyValidatorOptions = function () {
  const validator = $('form').data('validator');
  if (validator !== undefined && validator.length > 0) {
    validator.settings.ignoreTitle = true;
  }
};

/**
 * Applies masks to elements with given class names.
 * This function is seperated by design to allow post-instantiation application of masks. Depends on ValidationSetupMaskedItems() also being called to handle events.
 */
export const setupInputMasks = function () {
  Inputmask.extendDefaults({
    placeholder: '',
  });

  Inputmask({
    alias: 'datetime',
    inputFormat: 'mm/dd/yyyy',
    placeholder: 'MM/DD/YYYY',
    clearMaskOnLostFocus: false,
    showMaskOnFocus: false,
    showMaskOnHover: false,
    min: format(new Date('01/01/1900'), 'MM/dd/yyyy'),
    max: format(new Date(), 'MM/dd/yyyy'),
    insertModeVisual: false,
  }).mask('.mask-date');

  Inputmask({
    mask: '(999) 999-9999',
    removeMaskOnSubmit: true,
    clearMaskOnLostFocus: false,
    autoUnmask: true,
    placeholder: '_',
  }).mask('.mask-phone');

  Inputmask({
    regex: '^\\d{5}$',
  }).mask('.mask-zip');

  addNameInputValidation('#FirstName');
  addNameInputValidation('#LastName');
};

export const setupFullnameInputMask = () => {
  $('body').on('change', '.mask-fullname', function () {
    const $t = $(this);
    let masked = Inputmask.format($t.val(), {
      regex: "^[a-zA-ZÁÉÍÓÚÑÜáéíóúñüÿ' ]+$",
    });
    masked = capitalCaseString(masked);
    $t.val(masked);
  });
};
