import 'jquery-validation-unobtrusive';
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown';
import 'bootstrap-sass/assets/javascripts/bootstrap/affix';
import './utilities/smooth-scroll';
import './utilities/exists';
import './utilities/sticky-sidebar';
import './utilities/stat-animated';
import './common/header';
import {
  applyValidatorRules,
  setupInputMasks,
  modifyValidatorOptions,
  addValidatorMethods,
  setupFullnameInputMask,
} from './common/validations';
import {
  setupAjaxFormSubmitHandler,
  setupFullFormSubmitHandler,
  setupHeroZipFormSubmitHandler,
  setupZipFormSubmitHandler,
} from './common/forms';
import setupPageEventHandlers from './common/page-handlers';
import { addCarousel } from './common/carousel';
import { initializeAccessibility } from './utilities/a11y';

document.addEventListener('DOMContentLoaded', () => {
  // Variables that will be used for some operations below
  // Kept at top so they are easily noticed and changed as needed
  // const newsletterUmbracoEndpoint = '/umbraco/api/newsletterapi/SubscribeByEmail';

  const heroZipFormEl = document.getElementById('HeroZipForm');
  const fullFormEl = document.getElementById('FullForm');
  const carouselEl = document.getElementById('js-content-carousel');

  setupPageEventHandlers();
  setupAjaxFormSubmitHandler();

  if (heroZipFormEl && fullFormEl) {
    setupHeroZipFormSubmitHandler();
  }

  setupFullFormSubmitHandler();
  setupZipFormSubmitHandler();

  if (carouselEl) {
    addCarousel();
  }
});

$(() => {
  $('#sidebar').stickySidebar();
  modifyValidatorOptions();
  addValidatorMethods();
  applyValidatorRules();
  setupInputMasks();
  setupFullnameInputMask();
  initializeAccessibility();
});
