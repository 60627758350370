import { TrackerEvents, TrackerProperties, trackGAEvent } from '../trackers';

export const setupZipFormSubmitHandler = () => {
  $('.formZip').on('submit', function () {
    const $t = $(this);
    if ($t.valid()) {
      const zipcode = $t.find('[name="Zipcode"]').val();
      trackGAEvent({
        [TrackerProperties.Event]: 'ZipCodeFormSubmit',
        [TrackerProperties.Zip]: zipcode,
      });
    }
  });
};

export const setupHeroZipFormSubmitHandler = () => {
  $('#HeroZipForm').on('submit', function (e) {
    e.preventDefault();
    const $t = $(this);
    if ($t.valid()) {
      const zipcode = $t.find('[name="Zipcode"]').val();
      $('#FullForm').find('[name="Zipcode"]').val(zipcode);
      $('html, body').animate(
        {
          scrollTop: $('#FullForm').parents('div.container').offset().top - 80,
        },
        700
      );
    }
  });
};

export const setupFullFormSubmitHandler = () => {
  $('.formFull').on('submit', function (e) {
    const $t = $(this);
    if ($t.valid()) {
      const zipcode = $t.find('[name="Zipcode"]').val();
      trackGAEvent({
        [TrackerProperties.Event]: TrackerEvents.ValidFormSubmission,
        [TrackerProperties.Zip]: zipcode,
      });
    }
  });
};

export const setupAjaxFormSubmitHandler = () => {
  // ajax subscribe
  $('.uAjaxForm').on('submit', function (e) {
    e.preventDefault();

    // Preparing data from the form to be submitted to the newsletter endpoint
    const $form = $(this);

    if (!$form.valid()) {
      if ($('.newsletter-error').length) {
        $('.newsletter-error')
          .removeClass('field-validation-valid')
          .addClass('field-validation-error')
          .html(
            'Please enter a valid email address to ensure you get your free guide!'
          );
      }
      return false;
    }
    if ($('.newsletter-error').length) {
      $('.newsletter-error')
        .hide()
        .addClass('field-validation-valid')
        .removeClass('field-validation-error');
    }
    const isDisabled =
      $form.find('[type="submit"]').attr('disabled') !== undefined;
    if (!isDisabled) {
      $form.find('[type="submit"]').prop('disabled', true);
      // Send message
      $.ajax({
        url: this.action,
        type: this.method,
        data: new FormData(this),
        cache: false,
        contentType: false,
        processData: false,
        success(data) {
          $('#response-modal').hide().fadeIn(600).html();
          trackGAEvent({
            [TrackerProperties.Event]: TrackerEvents.NewsletterEmailSubmission,
          });
          $form[0].reset();
        },
        error(xhr, error, status) {
          console.log(error, status);
        },
        complete() {
          $form.find('[type="submit"]').removeAttr('disabled');
        },
      });
    }
  });
};
