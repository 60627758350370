const setupPageEventHandlers = () => {
  $('body').on('click', '.close-modal', function (e) {
    e.preventDefault();
    $(this).closest('.modal').fadeOut(600);
  });

  $('input.js-label').on('change', function () {
    const $t = $(this);
    $t.toggleClass('not-empty', $t.val());
  });

  // slide toggle primary site navigation for mobile/tablet
  $('#open-site-nav').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    const fadeIn = function () {
      $('#close-site-nav').css('opacity', 1);
    };
    $(this).attr('aria-expanded', true);
    $('#site-nav').css('left', 0);
    setTimeout(fadeIn, 300);
  });

  const closeMenu = () => {
    $('#site-nav').css('left', '-100%');
    $('#close-site-nav').css('opacity', 0);
    $('#open-site-nav').attr('aria-expanded', false);
  };
  $('#close-site-nav').on('click', e => {
    e.preventDefault();
    closeMenu();
  });
  $('html').on('click', e => {
    if (!$(e.target).closest('#site-nav').length) {
      closeMenu();
    }
  });

  $('#site-nav').on('show.bs.dropdown', function () {
    if ($('.hidden-md').css('display') !== 'none') {
      $(this).find('.nav').css({ right: '110%', left: 'auto' });
    }
  });
  $('#site-nav').on('hide.bs.dropdown', function () {
    if ($('.hidden-md').css('display') !== 'none') {
      $(this).find('.nav').css({ right: '0', left: 'auto' });
    }
  });
  if ($('.scroll-replace').length) {
    $(document)
      .on('scroll', () => {
        const $navbar = $('.scroll-replace');
        if ($(window).scrollTop() > 0) {
          $navbar.addClass('sticky');
        } else {
          $navbar.removeClass('sticky');
        }
      })
      .trigger('scroll');
  }

  $('#js-header-affix > header').affix({
    offset: {
      top: $('#js-header').height(),
    },
  });

  $('.js-jumpnav').on('change', function () {
    if ($(this).val() !== '') {
      window.location = $(this).val();
    }
  });

  $('#scroll-calc').on('click', function (e) {
    e.preventDefault();
    $(this).scrollTo();
  });
};

export default setupPageEventHandlers;
